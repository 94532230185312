import Image from "next/image";
import React from "react";

const EmptyState = ({
  desc = "We cannot find your search. Please search again.",
  title = "Whoops! We didn’t find anything.",
}: {
  desc?: string;
  title?: string;
}) => {
  return (
    <div className="min-h-[500px] lg:min-h-[712px] flex flex-col gap-3 justify-center items-center">
      <div className="w-fit">
        <Image
          src="/assets/images/no-results-found.svg"
          height={400}
          width={400}
          alt="Empty State"
        />
      </div>

      <div className="py-5 -mt-8 text-center">
        <h3 className="text-primary inter body-lg-bold mt-5">{title}</h3>
        <p className="text-secondary body-sm-regular inter max-w-md mx-auto mt-2">
          {desc}
        </p>
      </div>
    </div>
  );
};

export {EmptyState};
